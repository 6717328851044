
import { defineComponent } from "vue";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import api from "@/services/api";
import DxButton from "devextreme-vue/button";
import { ReportType } from "./ReportType";

export default defineComponent({
  components: {
    DxLoadPanel,
    DxButton,
  },
  props: {
    reportType: { required: true },
    reportTitle: { required: true },
  },
  computed: {
    isLoadingReport(): any {
      return (
        this.reportType == ReportType.LoadingReport ||
        this.reportType == ReportType.LoadingRZDReport
      );
    },
    reportTypeName(): any {
      return ReportType[this.reportType];
    },
  },
  methods: {
    onSwitchChange() {
      if (this.isSettingsOpen === true) this.isSettingsOpen = false;
      else this.isSettingsOpen = true;
    },
    onShowInfo() {
      this.toggleInfoDialog = !this.toggleInfoDialog;
    },
    createViewer() {
      (
        window as any
      ).Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile(
        `${window.location.protocol}//${window.location.host}/stimulsoft/localization/ru.xml`
      );
      const options = new (window as any).Stimulsoft.Viewer.StiViewerOptions();
      options.toolbar.showAboutButton = false;
      options.toolbar.viewMode = (
        window as any
      ).Stimulsoft.Viewer.StiWebViewMode.Continuous;
      this.viewer = new (window as any).Stimulsoft.Viewer.StiViewer(
        options,
        "StiViewer",
        false
      );

      this.viewer.renderHtml("viewer");
      this.viewer.showProcessIndicator();
    },
    async getSchemeCount(params: any) {
      const { data } = await api.get(`/${this.reportTypeName}/GetSchemeCount`, {
        params: params,
      });
      return data;
    },
    async fillReportData(params: any) {
      this.report = new (window as any).Stimulsoft.Report.StiReport();
      const { data } = await api.get(`/${this.reportTypeName}/GetReportTemplate`);

      this.report.load(data);

      if (this.stopping) return;

      const dataSetName = "Data";
      const dataSet = new (window as any).Stimulsoft.System.Data.DataSet(
        dataSetName
      );
      if (this.stopping) return;
      let res = await api.get(`/${this.reportTypeName}/GetReportData`, {
        params: params,
      });
      if (this.stopping) return;

      dataSet.readJson(res.data);

      if (this.stopping) return;
      this.report.dictionary.databases.clear();

      this.report.regData(dataSetName, dataSetName, dataSet);
      if (this.stopping) return;
      this.report.dictionary.synchronize();
      if (this.stopping) return;
    },
    renderReport() {
      if (this.viewer && this.report) {
        this.report.renderAsync(() => {
          if (this.stopping) return;
          this.viewer.report = this.report;
          this.loading = false;
          this.timeMessage = "";
          this.endTime = new Date().getTime();
          const reportData = (this as any).$store.state.reportData.reportData;
          let time =
            (this.endTime - this.startTime) / (1000 * this.schemeCount);
          if (this.isLoadingReport && this.schemeCount != 0) {
            const temp = {
              data: {
                generationTime: this.schemeCount < 10 ? time / 2 : time,
                generationTimePdf: reportData.generationTimePdf
                  ? reportData.generationTimePdf
                  : null,
              },
            };
            (this as any).$store.dispatch("reportData/setReportData", temp);
          }
        });
      }
    },
    setTimeMessage(generationTime: any) {
      if (this.schemeCount) {
        const time = Math.round(this.schemeCount);
        this.timeMessage =
          time > 60
            ? "\nПриблизительное время формирования отчета - " +
              Math.floor(time / 60) +
              " мин " +
              Math.round(time % 60) +
              " сек."
            : "\nПриблизительное время формирования отчета - " +
              Math.round(time % 60) +
              " сек.";
      } else {
        this.timeMessage = "";
      }
    },
    async onReport(params: any) {
      if (this.isLoadingReport) {
        const reportData = (this as any).$store.state.reportData.reportData;
        this.schemeCount = await this.getSchemeCount(params);
        let time = null;
        if (reportData.generationTime != null) {
          time =
            this.schemeCount < 10
              ? reportData.generationTime * 2
              : reportData.generationTime;
        }
        this.setTimeMessage(time);
      }
      this.loading = true;
      this.stopping = false;
      this.startTime = new Date().getTime();
      await this.fillReportData(params);
      if (this.stopping) return;
      this.renderReport();
    },
    async onDownload(params: any) {
      const reportData = (this as any).$store.state.reportData.reportData;
      this.schemeCount = await this.getSchemeCount(params);
      this.setTimeMessage(reportData.generationTimePdf);
      this.loading = true;
      this.stopping = false;
      this.startTime = new Date().getTime();
      const { data } = await api.get(`/${this.reportTypeName}/GetReport`, {
        responseType: "blob",
        params: params,
      });
      if (this.stopping) return;
      let blob = new Blob([data], { type: "application/octet-stream" });
      let link = document.createElement("a");
      if (this.stopping) return;
      link.href = window.URL.createObjectURL(blob);
      if (this.stopping) return;
      link.download = `Report.pdf`;
      link.click();
      link.remove();
      this.loading = false;
      this.timeMessage = "";
      this.endTime = new Date().getTime();
      if (this.schemeCount != 0) {
        const temp = {
          data: {
            generationTime: reportData.generationTime
              ? reportData.generationTime
              : null,
            generationTimePdf:
              (this.endTime - this.startTime) / (1000 * this.schemeCount),
          },
        };
        (this as any).$store.dispatch("reportData/setReportData", temp);
      }
    },
    onStop() {
      this.stopping = true;
      this.loading = false;
    },
  },
  created() {
    (window as any).Stimulsoft.Base.StiLicense.key =
      process.env.VUE_APP_STIMULSOFT_KEY;
  },
  mounted() {
    this.createViewer();
  },
  data() {
    return {
      schemeCount: null,
      startTime: null,
      endTime: null,
      isSettingsOpen: true,
      loading: false,
      toggleInfoDialog: false,
      stopping: false,
      report: {},
      viewer: {},
      loadingPanelPosition: { of: "#loadingPanelPlace" },
      timeMessage: "",
    };
  },
});
